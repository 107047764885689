<template>
  <section class="separator">
    <div class="content">
      <img class="border-image" src="../assets/images/stripe-tape.png" />
      <div class="center">
        <div class="left-text">Поисковый трафик, белый арбитраж</div>
        <div :style="{ top: `${imageOffset}px` }" class="center__image">
          <img src="../assets/images/rocket.png" />
        </div>
        <div class="right-text">Арбитражники, давайте к нам!</div>
      </div>
      <img class="border-image bottom" src="../assets/images/stripe-tape.png" />
    </div>
  </section>
</template>

<script>
export default {
  name: "SeparatorSection",

  data() {
    return {
      imageOffset: -35,
      isMounted: true,
    };
  },

  computed: {
    isMobile() {
      return window.innerWidth < 744;
    },
  },

  methods: {
    handleScroll() {
      const speed = 0.7;
      const sectionTop = this.$el.getBoundingClientRect().top;
      let windowOffset = window.innerHeight - window.innerHeight / 3;
      let defaultOffset = -35;

      if (this.isMobile) {
        windowOffset = window.innerHeight / 4;
        defaultOffset = 90;
      }

      const offset = sectionTop - windowOffset;

      if (this.isMounted) {
        this.imageOffset = Math.abs(offset * speed);
        this.isMounted = false;
        return;
      }

      const imageTop = offset * speed;

      if (imageTop > defaultOffset) {
        this.imageOffset = imageTop;
      } else {
        this.imageOffset = defaultOffset;
      }
    },
  },

  mounted() {
    this.handleScroll();
  },

  created() {
    document.addEventListener("scroll", this.handleScroll);
  },

  beforeUnmount() {
    document.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped lang="sass">
@import "../styles/variables.sass"

.separator
  width: 100%
  background: #F8F7FF

  .content
    width: 100%
    display: flex
    flex-direction: column
    overflow: hidden
    position: relative

    .border-image
      width: fit-content
      height: 30px

      @media screen and (min-width: $desktop_large)
        width: 100%

      &.bottom
        z-index: 9

      img
        height: 100%

    .center
      width: 100%
      display: flex
      flex-direction: column
      align-items: flex-end
      justify-content: space-evenly
      padding: 16px 0
      gap: 17px

      @media screen and (min-width: $mobile_medium)
        gap: 10px

      @media screen and (min-width: $desktop_small)
        padding: 33px 0
        flex-direction: row
        justify-content: center
        align-items: center
        gap: 428px

      @media screen and (min-width: $desktop_medium)
        gap: 555px

      .left-text
        transform: rotate(-2deg)
        font-size: 26px
        font-weight: 590
        line-height: 33.8px
        text-align: center
        color: #928AE4
        z-index: 11
        max-width: 270px
        padding-right: 100px

        @media screen and (min-width: $mobile_medium)
          max-width: 585px
          font-size: 36px
          line-height: 46.8px
          padding-left: 270px
          align-self: unset

        @media screen and (min-width: $desktop_small)
          font-size: 50px
          line-height: 65px
          padding-left: 0
          padding-right: 0

        @media screen and (min-width: $desktop_medium)
          font-size: 58px
          line-height: 75.4px

      .right-text
        transform: rotate(2deg)
        font-size: 26px
        font-weight: 590
        line-height: 33.8px
        text-align: center
        color: #736DCA
        z-index: 10
        max-width: 200px
        padding-right: 5.5px

        @media screen and (min-width: $mobile_medium)
          max-width: 433px
          font-size: 36px
          line-height: 46.8px
          padding-right: 55px

        @media screen and (min-width: $desktop_small)
          padding-right: 140px
          font-size: 50px
          line-height: 65px

        @media screen and (min-width: $desktop_medium)
          font-size: 58px
          line-height: 75.4px

      &__image
        width: auto
        height: 100%
        position: absolute
        bottom: -90px
        top: unset
        z-index: 9
        left: -45px

        img
          height: 160px

        @media screen and (min-width: $mobile_medium)
          width: auto
          height: 100%
          position: absolute
          top: -35px
          left: -65px
          bottom: unset
          z-index: 9

          img
            height: unset

        @media screen and (min-width: $desktop_small)
          left: unset
          z-index: 9
</style>
