<template>
  <section class="hero">
    <div class="content">
      <swiper
        loop
        navigation
        :modules="modules"
        :slides-per-view="1"
        :slides-per-group="1"
        :space-between="0"
        :pagination="pagination"
        :autoplay="{
          delay: 2500,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }"
        @autoplayTimeLeft="onAutoplayTimeLeft"
        @slideChange="onSlideChange"
      >
        <swiper-slide v-for="(item, i) in data" :key="`Hero-swiper__slide${i}`">
          <img v-if="isMobile && item.mobileImage" :src="item.mobileImage" />
          <img v-else-if="!isMobile && item.image" :src="item.image" />
        </swiper-slide>
      </swiper>
      <div class="swiper-pagination"></div>
    </div>
  </section>
</template>

<script>
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { ref } from "vue";
export default {
  name: "HeroSection",
  components: {
    Swiper,
    SwiperSlide,
  },

  data() {
    return {
      isMobile: false,
    };
  },

  methods: {
    onSlideChange(swiper) {
      const barElement = swiper.el.querySelector(
        `.progress[name="progress${this.activeIndex}"]`
      );
      if (barElement) {
        barElement.style.width = 0;
      }
      this.activeIndex = swiper.realIndex;
    },
    handleResize() {
      if (window.innerWidth < 744) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
  },

  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },

  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },

  setup() {
    const activeIndex = ref(0);
    const data = ref([
      {
        image: "/images/hero/1.png",
        mobileImage: "/images/hero/1-mobile.png",
        progress: 0,
      },
      {
        image: "/images/hero/2.png",
        mobileImage: "/images/hero/2-mobile.png",
        progress: 0,
      },
      {
        image: "/images/hero/3.png",
        mobileImage: "/images/hero/3-mobile.png",
        progress: 0,
      },
      {
        image: "/images/hero/4.png",
        mobileImage: "/images/hero/4-mobile.png",
        progress: 0,
      },
      {
        image: "/images/hero/5.png",
        mobileImage: "/images/hero/5-mobile.png",
        progress: 0,
      },
      {
        image: "/images/hero/6.png",
        mobileImage: "/images/hero/6-mobile.png",
        progress: 0,
      },
      {
        image: "/images/hero/7.png",
        mobileImage: "/images/hero/7-mobile.png",
        progress: 0,
      },
      {
        image: "/images/hero/8.png",
        mobileImage: "/images/hero/8-mobile.png",
        progress: 0,
      },
      {
        image: "/images/hero/9.png",
        mobileImage: "/images/hero/9-mobile.png",
        progress: 0,
      },
      {
        image: "/images/hero/10.png",
        mobileImage: "/images/hero/10-mobile.png",
        progress: 0,
      },
      {
        image: "/images/hero/11.png",
        mobileImage: "/images/hero/11-mobile.png",
        progress: 0,
      },
      {
        image: "/images/hero/12.png",
        mobileImage: "/images/hero/12-mobile.png",
        progress: 0,
      },
    ]);
    const onAutoplayTimeLeft = (s, time, progress) => {
      const barElement = s.el.querySelector(
        `.progress[name="progress${activeIndex.value}"]`
      );
      data.value[activeIndex.value].progress = Math.round((1 - progress) * 100);
      if (barElement) {
        const widthPercent = Math.round((1 - progress) * 100);
        if (widthPercent < 99) {
          barElement.style.width = `${widthPercent}%`;
        } else {
          barElement.style.width = 0;
        }
      }
    };
    return {
      pagination: {
        clickable: true,
        renderBullet: function (index) {
          return `
            <span class="swiper-pagination-bullet">
              <div class="progress" name="progress${index}" style="width: 0%;"></div>
            </span>
          `;
        },
      },
      activeIndex,
      data,
      onAutoplayTimeLeft,
      modules: [Autoplay, Navigation, Pagination],
    };
  },
};
</script>

<style scoped lang="sass">
@import "../styles/variables.sass"

.hero
  width: 100%
  position: relative

.swiper-slide
  display: flex
  justify-content: center
  overflow: hidden

  img
    width: 100%
    object-fit: cover
    height: 100dvh

    @media screen and (min-width: $mobile_medium)
      height: 800px

.hero-swiper-pagination
  position: absolute
  bottom: 0
  z-index: 99
  display: flex
  flex-wrap: wrap
  justify-content: center
  gap: 16px
</style>

<style lang="sass">
@import "../styles/variables.sass"

.hero
  .swiper-pagination
    position: absolute!important
    bottom: 0
    z-index: 10
    display: flex
    flex-wrap: wrap
    justify-content: center
    gap: 4px
    padding-bottom: 33px

    @media screen and (min-width: $desktop_small)
      gap: 16px

    &-bullet
      background: #FFFFFF80!important
      width: 24px
      height: 5px
      border-radius: 40px
      margin: 0!important
      position: relative
      opacity: 100%!important

      @media screen and (min-width: $desktop_small)
        width: 90px!important
        margin: 0 8px

      &:hover
        opacity: 70%!important
        background: #FFFFFFFF!important

      &-active
        background: #FFFFFF80!important
        opacity: 100%!important

    .progress
      width: 0
      height: 5px
      background: #FFFFFFFF
      border-radius: 40px
      position: absolute
      left: 0
      top: 0
      z-index: 10

  .swiper-button-prev, .swiper-button-next
    top: 45%!important
</style>
