<template>
  <router-view />
</template>

<style lang="scss">
@import "./styles/fonts.sass";
@import "./styles/variables.sass";

#app {
  font-family: "SF Pro";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

body {
  margin: 0;
  cursor: url("./assets/cursor.svg"), auto;
}
</style>
