<template>
  <section class="achievements">
    <div class="content">
      <div class="item left">
        <div class="title">Только свои продукты под iOS & Android</div>
        <img src="../assets/images/phone.png" class="main-image" />
        <img
          src="../assets/images/achievents-background.png"
          class="achievemnts-background"
        />
        <img
          src="../assets/images/graduation-cap-icon.svg"
          class="graduation-cap"
        />
        <img
          src="../assets/images/movie-clapper-icon.svg"
          class="movie-clapper"
        />
        <img src="../assets/images/chart-icon.svg" class="chart-icon" />
        <img
          src="../assets/images/paint-brushes-icon.svg"
          class="paint-brushes"
        />
      </div>
      <div class="item right">
        <img src="../assets/images/about.png" />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "AchievementsSection",
};
</script>

<style scoped lang="sass">
@import "../styles/variables.sass"
@import "../styles/mixins.sass"

.achievements
  width: 100%
  background: #FFFFFF

  .content
    width: 100%
    display: flex
    flex-direction: column
    gap: 24px
    overflow: hidden

    @media screen and (min-width: $desktop_small)
      flex-direction: row

    .item
      width: 100%
      position: relative

      @media screen and (min-width: $desktop_small)
        width: 50%

      img
        width: 100%
        height: auto
        object-fit: cover

      &.left
        display: flex
        justify-content: flex-end
        align-items: center
        flex-direction: column
        background: #F8F7FF

        .title
          font-size: 26px
          line-height: 33.8px
          font-weight: 590
          text-align: center
          color: #736DCA
          max-width: 343px
          position: unset
          top: 32px
          padding: 0 16px

          @media screen and (min-width: $mobile_medium)
            top: 48px
            font-size: 50px
            line-height: 65px
            padding: 0
            max-width: 618px

          @media screen and (min-width: $desktop_large)
            position: absolute

        .main-image
          object-fit: contain
          z-index: 4

          @media screen and (min-width: $desktop_medium)
            max-width: 70%

        .achievemnts-background
          position: absolute
          left: 0
          bottom: 0
          z-index: 2
          width: auto
          max-height: 90%

          @media screen and (min-width: $desktop_small)
            width: 100%
            max-width: 74.8%
            height: auto

        .graduation-cap
          position: absolute
          top: 70px
          left: 0%
          max-width: 23%
          width: 100%
          z-index: 3
          transform: rotate(-13.22deg)
          @include levitateAnimation(-13.22deg)

          @media screen and (min-width: $mobile_medium)
            max-width: 21.8%
            left: 1%
            top: 142px

          @media screen and (min-width: $desktop_small)
            max-width: 21.8%
            left: 1%
            top: 142px

          @media screen and (min-width: $desktop_medium)
            left: 13%
            max-width: 180px

          @media screen and (min-width: $desktop_large)
            left: 16%
            max-width: 14.2%

        .movie-clapper
          position: absolute
          top: 114px
          right: 7%
          max-width: 23%
          width: 100%
          z-index: 5
          transform: rotate(-9.18deg)
          @include levitateAnimation(-13.22deg)

          @media screen and (min-width: $mobile_medium)
            max-width: 21.8%
            right: 7.5%
            top: 32%

          @media screen and (min-width: $desktop_small)
            max-width: 21.8%
            right: 7.5%
            top: 250px

          @media screen and (min-width: $desktop_medium)
            max-width: 180px
            right: 18%
            top: 190px

          @media screen and (min-width: $desktop_large)
            max-width: 14.2%
            right: 21%
            top: 225px

        .chart-icon
          position: absolute
          bottom: 80px
          left: -4%
          max-width: 23%
          width: 100%
          z-index: 5
          transform: rotate(5.1deg)
          @include levitateAnimation(-13.22deg)

          @media screen and (min-width: $mobile_medium)
            max-width: 21.8%
            left: -4%

          @media screen and (min-width: $desktop_small)
            max-width: 21.8%
            left: -4%

          @media screen and (min-width: $desktop_medium)
            max-width: 180px
            left: 12%
            bottom: 17%

          @media screen and (min-width: $desktop_large)
            max-width: 14.2%
            bottom: 185px

        .paint-brushes
          position: absolute
          bottom: 25.5px
          right: 2%
          max-width: 23%
          width: 100%
          z-index: 3
          transform: rotate(18.42deg)
          @include levitateAnimation(-13.22deg)

          @media screen and (min-width: $mobile_medium)
            max-width: 21.8%
            right: 3%
            bottom: 60.5px

          @media screen and (min-width: $desktop_small)
            max-width: 21.8%
            right: 2%
            bottom: 65.5px

          @media screen and (min-width: $desktop_medium)
            max-width: 180px
            right: 15%

          @media screen and (min-width: $desktop_large)
            max-width: 14.2%
            bottom: 80.5px
            right: 17.5%

      &.right
        background: #F8F7FF

        img
          width: 100%
          height: 100%
</style>
