<template>
  <div class="home">
    <div class="sticky-top-logo">
      <img src="../assets/images/createx-logo.png" />
    </div>
    <hero-section></hero-section>
    <achievements-section></achievements-section>
    <separator-section></separator-section>
    <tik-tok-section></tik-tok-section>
  </div>
  <footer-copyright></footer-copyright>
</template>

<script>
import HeroSection from "@/components/HeroSection.vue";
import AchievementsSection from "@/components/AchievementsSection.vue";
import SeparatorSection from "@/components/SeparatorSection.vue";
import FooterCopyright from "./FooterCopyright.vue";
import TikTokSection from "@/components/TikTokSection.vue";

export default {
  name: "HomeView",
  components: {
    HeroSection,
    AchievementsSection,
    SeparatorSection,
    FooterCopyright,
    TikTokSection,
  },
};
</script>

<style lang="sass">
@import "../styles/variables.sass"

.home
  width: 100%
  height: 100%
  background: #FFFFFF

  section
    margin-bottom: 24px

  .sticky-top-logo
    width: 100%
    display: flex
    justify-content: center
    position: fixed
    top: 0
    z-index: 20
    height: 64px

    @media screen and (min-width: $mobile_medium)
      img
        height: fit-content

.swiper
  position: unset

  .swiper-button-prev
    display: none
    width: fit-content
    height: 80px
    padding: 0px 19px 0px 10px
    border-radius: 0px 28px 28px 0px
    background: #00000059
    left: 0
    top: 40%
    cursor: url("../assets/cursor.svg"), auto

    &::after
      width: 11px
      content: url("../assets/images/arrow-left.svg")
      font-size: 32px

    &:hover
      background: #00000080

    @media screen and (min-width: $desktop_small)
      display: flex

  .swiper-button-next
    display: none
    width: fit-content
    height: 80px
    padding: 0px 10px 0px 19px
    border-radius: 28px 0px 0px 28px
    background: #00000059
    right: 0
    top: 40%
    cursor: url("../assets/cursor.svg"), auto

    &::after
      width: 11px
      content: url("../assets/images/arrow-right.svg")
      font-size: 32px

    &:hover
      background: #00000080

    @media screen and (min-width: $desktop_small)
      display: flex

  .swiper-pagination
    position: unset
    margin-top: 33px

    @media screen and (min-width: $mobile_medium)
      margin-top: 48px

    &-bullet
      background: #C5C0F1
      width: 24px
      height: 5px
      border-radius: 40px
      margin: 0 8px
      cursor: url("../assets/cursor.svg"), auto

      @media screen and (min-width: $mobile_medium)
        width: 40px

      @media screen and (min-width: $desktop_small)
        width: 110px

      &-active
        background: #928AE4
</style>
