<template>
  <section
    class="tagembed-widget"
    style="width: 100%; height: 100%"
    data-widget-id="2142767"
    data-tags="false"
    view-url="https://widget.tagembed.com/2142767"
  ></section>
</template>

<script>
export default {
  name: "TikTokSection",
  created() {
    const scriptTag = document.createElement("script");
    scriptTag.src = "https://widget.tagembed.com/embed.min.js";
    scriptTag.setAttribute("charset", "utf-8");
    document.head.appendChild(scriptTag);
  },
};
</script>

<style lang="sass">
.tagembed-widget
  cursor: url("../assets/cursor.svg"), auto !important

  .tb_hc_arrow
    width: 40px
    height: 80px
    background: #00000059

    &:before
      color: #FFF

  .tb_hc_arrow_left__
    border-radius: 0px 28px 28px 0px
    left: 0

  .tb_hc_arrow_right__
    border-radius: 28px 0px 0px 28px
    right: 0

  .tb_hc_content
    font-family: "SF Pro"

  .tb_hc_author_wrapper
    display: none

.tb_post_modal_content_wrap, .tb_post_modal_content, .tb_post_modal_post_report_media_btn__, .tb_post_modal_view
  font-family: "SF Pro" !important
</style>
